<template>
  <a-date-picker style="width: 100%" :value="value" format="YYYY-MM-DD ddd HH:mm"
    :show-time="{ defaultValue: moment('00:00', 'HH:mm') }" @change="change" valueFormat="YYYY-MM-DD HH:mm:ss"/>
</template>
<script>
import moment from 'moment';
export default {
  props: {
    value: String
  },
  methods: {
    moment,
    change(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  }
}
</script>